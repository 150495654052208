<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">

            <!-- Forgot Password v1 -->
            <b-card class="mb-0">
                <b-link class="brand-logo" to="/">
                    <!-- logo -->
                    <vuexy-logo />

                    <h2 class="brand-text text-primary ml-1">
                        ONEPOST
                    </h2>
                </b-link>

                <b-card-title class="mb-1">
                    Forgot Password? 🔒
                </b-card-title>
                <b-card-text class="mb-2">
                    Enter your email and we'll send you instructions to reset your password
                </b-card-text>

                <!-- form -->
                <validation-observer ref="simpleRules">
                    <b-form class="auth-forgot-password-form mt-2" @submit.prevent="validationForm">
                        <!-- email -->
                        <b-form-group label="Email" label-for="forgot-password-email">
                            <validation-provider #default="{ errors }" name="Email" rules="required|email">
                                <b-form-input type="email" id="forgot-password-email" v-model="userEmail"
                                    :state="errors.length > 0 ? false : null" name="email"
                                    placeholder="john@example.com" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- submit button -->
                        <b-button variant="primary" block type="submit">
                            Send reset link
                        </b-button>
                    </b-form>
                </validation-observer>

                <b-card-text class="text-center mt-2">
                    <b-link :to="{ name: 'login' }">
                        <feather-icon icon="ChevronLeftIcon" /> Back to login
                    </b-link>
                </b-card-text>

            </b-card>
            <!-- /Forgot Password v1 -->
        </div>
    </div>
</template>
  
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
    BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
    components: {
        VuexyLogo, ToastificationContent,
        BCard,
        BLink,
        BCardText,
        BCardTitle,
        BFormGroup,
        BFormInput,
        BButton,
        BForm,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            userEmail: '',
            // validation
            required,
            email,
        }
    },
    methods: {
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    axios.post(`/send_reset_password_link/${this.userEmail}/`)
                    .then(response => {
                        console.log(response);
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Reset password link sent successfully',
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        })
                    })
                    .catch(error => {
                        console.log(error);
                    })
                }
            })
        },
    },
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
  